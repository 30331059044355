// Empty

import axios, { AxiosError, AxiosResponse } from "axios";
import { API_BASE_URL } from "const/api";
import { ILink, IMasterLink } from "types/links/links";
import { LinkType } from "types/links/linkType";
import { ISection } from "types/links/section";
import { IProfile } from "types/profile/profile";

export async function getPageData(name: string): Promise<PageData> {
    return await axios.get(API_BASE_URL + `/api/page/${name}`, {
        headers: {
            'Content-Type': "application/json"
        }
    }
    ).then((response: AxiosResponse<PageData>) => {
        return response.data;
    }
    ).catch((err: AxiosError<any>) => {
        throw err;
    });
}

export interface PageData {
    profile: IProfile,
    linkTypes: LinkType[],
    links: ILink[],
    sections: ISection[],
    masterLinks: IMasterLink[]
}