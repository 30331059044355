/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { useState } from "react";
// Chakra imports
import {
  Box,
  Button,
  Container,
  Text,
  createIcon,
  Heading,
  Link,
  Icon,
  Stack,
  useColorModeValue,
  Flex,
  Image,
} from "@chakra-ui/react";
// Custom components
// Assets
import { useToast } from "@chakra-ui/react";
import _ from "lodash";
import splash from "assets/splash.jpg";
import phoneshot from "assets/img/phoneshot.png";

function HomeView() {
  // Chakra color mode
  const toast = useToast();
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [loading, setLoading] = useState(false);

  return (
    <Box maxW={'100%'} overflow='hidden'>
      <Box height={"100vh"} bg={`url(${splash})`} backgroundSize="cover">
        <Flex h={"100%"} flexDirection={"column"} justifyContent="center">
          <Flex
            justifyContent="left"
            alignItems={"center"}
            direction="row"
            borderRadius="10px"
            padding="20px 60px"
          >
            <Box>
              <Heading color={"white"} fontSize="100px">
                Sty.re
              </Heading>
              <Flex>
                <Text color={"white"} ml="30px" align="left" fontSize="40px">
                  All your links
                </Text>
              </Flex>
              <Text
                as="i"
                color={"white"}
                ml="50px"
                align="left"
                fontSize="40px"
                textStyle={"italic"}
              >
                - one url
              </Text>
            </Box>
          </Flex>
          <Flex w={"100%"} justifyContent="end" mt="40px" pr={"10%"}>
            <Link href={"https://app.sty.re"}>
              <Button h={"60px"}>Get started now for free</Button>
            </Link>
          </Flex>
        </Flex>
      </Box>
      <Box>
        <Flex  flexDirection={"column"} justifyContent={"end"}>
          <Box pl={"40px"} w={{
            base: '100%',
            xl: '60%' 
          }}>
            <Heading size={"2xl"} mt={"40px"} mb="40px">
              How does it work?
            </Heading>
            <Text fontSize={"20px"}>
              Sty.re lets you claim a short URL, which you can use in your bio
              to easily allow your followers to find your other online
              presences.
            </Text>
            <Text fontSize={"20px"} mt="5px">
              Use Sty.re to broadcast your social media links, your online
              stores, or links to whatever other elements you want to show off
              in the world wide web!
            </Text>
          </Box>
          <Flex w={"100%"} justifyContent="end" mt="40px" pr={"10%"}>
            <Link href={"https://app.sty.re"} isExternal>
              <Button variant={"brand"} h="60px">
                Get started now for free
              </Button>
            </Link>
          </Flex>
        </Flex>
        <Flex justifyContent={{base: 'center', xl: 'start'}} ml={{base: '60px', xl: '200px'}} mt={{base: '60px', xl: '0px'}} mb='40px'>
          <Image src={phoneshot} height='600px' transform={'rotate(10deg)'} />
        </Flex>
      </Box>
    </Box>
  );
}

export default HomeView;
