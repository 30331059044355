/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Box,
  Flex,
  Grid,
  Link,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";

// Custom components
import Banner from "views/profile/components/Banner";
import ActionCenter from "views/profile/components/ActionCenter";

// Assets
import { useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import { batch, useDispatch, useSelector } from "react-redux";
import { IRootState } from "redux/store";
import { getPageData } from "utils/api/apiConnector";
import { useLocation } from "react-router-dom";
import {
  populateLinksFromServer,
  saveLinkTypes,
} from "redux/slice/profileLinksSlice";
import { saveProfile } from "redux/slice/profileSlice";
import Section from "./components/Section";

export default function ProfileView(props: any) {
  const { onOpen } = useDisclosure();
  const { ...rest } = props;
  const dispatch = useDispatch();
  // states and functions
  const [fixed] = useState(false);
  const auth = getAuth();
  const location = useLocation();
  const textColor = useColorModeValue("gray.400", "white");
  //Profile
  const firstName = useSelector((state: IRootState) => state.profile.firstName);
  const lastName = useSelector((state: IRootState) => state.profile.lastName);
  const bio = useSelector((state: IRootState) => state.profile.bio);
  const sectionWeakIds = useSelector(
    (state: IRootState) => state.links.sections.allWeakIds
  );
  const occupation = useSelector(
    (state: IRootState) => state.profile.occupation
  );
  const email = useSelector((state: IRootState) => state.profile.email);
  const coverPhotoUrl = useSelector(
    (state: IRootState) => state.profile.coverPhotoUrl
  );
  const profilePhotoUrl = useSelector(
    (state: IRootState) => state.profile.profilePhotoUrl
  );
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    reloadPageData();
  });

  const reloadPageData = async (): Promise<void> => {
    if (!loaded) {
      setLoaded(true);
      const data = await getPageData(location.pathname.substring(1));
      batch(() => {
        dispatch(saveLinkTypes(data.linkTypes));
        dispatch(populateLinksFromServer(data));
        dispatch(saveProfile(data.profile));
      });
    }
  };

  const getLinkSections = (): React.ReactElement[] => {
    return sectionWeakIds.map((wid) => (
      <Section key={wid} weakId={wid}></Section>
    ));
  };

  const getName = (): string => {
    let res = "";
    if (!firstName && !lastName) {
      return "No Name";
    }
    if (firstName) {
      res = firstName;
    }
    if (lastName) {
      res = `${res} ${lastName}`;
    }
    return res;
  };
  return (
    <Box pt={"20px"}>
      <Banner
        banner={coverPhotoUrl}
        avatar={profilePhotoUrl}
        email={email}
        name={getName()}
        job={occupation}
        bio={bio}
      />
      {getLinkSections()}
      <Flex mt={"40px"} mb='20px' w='100%' justifyContent={'center'}>
        <Link fontWeight="500" fontSize={'24px'} color={textColor} href="https://sty.re/">
          Built with Sty.re
        </Link>
      </Flex>
    </Box>
  );
}
