import { StringLiteralLike } from "typescript"

export enum LinkTypeId {
    TWITTER = 'twitter',
    FACEBOOK = 'facebook',
    OTHER = 'other',
    SUB_LINK = 'sublink'
}

export interface LinkType {
    id: LinkTypeId,
    label: string,
    basePath: string,
    color: string
}


export const TWITTER_LINK_TYPE = {
    label: "Twitter",
    id: LinkTypeId.TWITTER,
    basePath: "https://twitter.com/",
    color: '#000'
}

export const FACEBOOK_LINK_TYPE = {
    label: "Facebook",
    id: LinkTypeId.FACEBOOK,
    basePath: "https://facebook.com/",
    color: '#000'
}

export const OTHER_LINK_TYPE = {
    label: "Other",
    id: LinkTypeId.OTHER,
    basePath: "https://",
    color: '#00bf00'
}

export const linkTypesArray: LinkType[] = [
    TWITTER_LINK_TYPE,
    FACEBOOK_LINK_TYPE,
    OTHER_LINK_TYPE,
]