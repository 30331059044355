import React, { useState } from "react";
import { BrowserRouter, Route, Router, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Box, Flex, Spinner, useColorModeValue, Text, Fade } from "@chakra-ui/react";
import Home from './layouts/home'
import Profile from "layouts/profile";

export default function App() {

  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const [initialized, setInitialized] = useState<boolean>(true);
  const dispatch = useDispatch();

  return (
    <React.StrictMode>
      <Box transition="1s all">
        <Fade in={!initialized} className='fader'>
          <Flex w='100vw' h={'110vh'} background='white' pos='absolute' top={0} left={0} height='100vh' zIndex={999} overflow='hidden' justifyContent='center' pt='50px'>
            <Flex flexDirection='column'>
              <Spinner
                alignSelf='center'
                mb='20px'
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color={textColorPrimary}
                size='xl'
              />
              <Text>Sty.re is loading...</Text>
            </Flex>
          </Flex>
        </Fade>
        <Fade in={initialized} className='fader'>
        <BrowserRouter>
          <Routes>
            <Route path={"/"} element={<Home />} />
            <Route path={`/*`} element={<Profile />}></Route>
          </Routes>
        </BrowserRouter>
      </Fade>
      </Box>
    </React.StrictMode>
  );
}
