import {
  Box,
  useColorModeValue,
  Button,
  Link,
  useColorMode,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { IRootState } from "redux/store";

interface LinkButtonProps {
  weakId: string;
  color?: string;
}

export default function LinkButton(props: LinkButtonProps) {
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const link = useSelector(
    (state: IRootState) => state.links.links.byWeakId[props.weakId]
  );
  const { colorMode, toggleColorMode } = useColorMode()
  const bgColor = colorMode === 'dark' ? 'white' : props.color;
  const textColor = colorMode === 'dark' ? props.color : 'white';

  return (
    <Box>
      {props.color && (
        <Box display={"flex"} justifyContent="end" mr={"10px"} w="100%">
          <Link href={link.prepend + link.url} isExternal target={"_blank"} w='100%'>
          <Button bg={bgColor} color={textColor} _hover={{
                filter: 'brightness(0.8)'
            }} w="100%">
              {link.title || "Visit"}
            </Button>
          </Link>
        </Box>
      )}
      {!props.color && (
        <Box
          display={"flex"}
          key={props.weakId}
          justifyContent="end"
          mr={"10px"}
          w="100%"
          mt="10px"
        >
          <Link href={link.prepend + link.url}  isExternal target={"_blank"} w='100%'>
            <Button variant={"lightBrand"} w="100%">
              {link.title || "Visit"}
            </Button>
          </Link>
        </Box>
      )}
    </Box>
  );
}
