import { combineReducers, configureStore } from "@reduxjs/toolkit";
import profileReducer from "./slice/profileSlice";
import linkReducer from "./slice/profileLinksSlice";
export const store = configureStore({
  reducer: {
    profile: profileReducer,
    links: linkReducer
  },
});

const rootReducer = combineReducers({ profile: profileReducer, links: linkReducer });

export type IRootState = ReturnType<typeof rootReducer>;