import { ReactNode } from "react";
import { Box, Text, useColorModeValue, Flex } from "@chakra-ui/react";
import { LinkType, OTHER_LINK_TYPE } from "types/links/linkType";
import { SocialIcon } from "react-social-icons";
import Card from "components/card/Card";
import { useSelector } from "react-redux";
import { IRootState } from "redux/store";
import LinkButton from "./LinkButton";
import { title } from "process";
import * as allIcons from "react-icons/fa";

interface SortableItemProps {
  weakId: string; // WeakId of masterlink
}

const MasterLink = (props: SortableItemProps) => {
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const brandColor = useColorModeValue("brand.500", "white");
  const textColorSecondary = "gray.400";

  const masterLinkTitle = useSelector(
    (state: IRootState) => state.links.masterLinks.byWeakId[props.weakId].title
  );
  const masterLinkColor = useSelector(
    (state: IRootState) =>
      state.links.masterLinks.byWeakId[props.weakId].customColor
  );
  const activeLinkTypeUid = useSelector(
    (state: IRootState) => state.links.masterLinks.byWeakId[props.weakId].type
  );
  const primaryLinkWeakId = useSelector(
    (state: IRootState) =>
      state.links.masterLinks.byWeakId[props.weakId].primaryLinkWeakId
  );
  const primaryLinkUrlValue = useSelector(
    (state: IRootState) => state.links.links.byWeakId[primaryLinkWeakId].url
  );

  const customIconName = useSelector(
    (state: IRootState) =>
      state.links.masterLinks.byWeakId[props.weakId].customIcon
  );

  const linkWeakIds = useSelector(
    (state: IRootState) =>
      state.links.masterLinks.byWeakId[props.weakId].linkWeakIds
  );
  const linkType =
    useSelector((state: IRootState) => state.links.linkTypes).find(
      (o) => o.id === activeLinkTypeUid
    ) || OTHER_LINK_TYPE;

  const getIcon = (type: LinkType, large = false): JSX.Element => {
    const style = {
      height: large ? 60 : 25,
      width: large ? 60 : 25,
    };
    if (type.id !== OTHER_LINK_TYPE.id) {
      return <SocialIcon network={type.id} style={style} />;
    }
    if (customIconName) {
      const matchingCustomIcons = Object.entries(allIcons).find(
        (ic) => ic[0] === customIconName
      );
      let customIcon = null;
      if (matchingCustomIcons && matchingCustomIcons.length > 0) {
        customIcon = matchingCustomIcons[1];
        return (
          <Box>
            {customIcon({ size: "3.5em", color: masterLinkColor || "" })}
          </Box>
        );
      } else {
        console.error(`Could not find icon ${customIconName}`);
      }
    }
    return (
      <SocialIcon
        bgColor={masterLinkColor ? masterLinkColor : ""}
        network={undefined}
        style={style}
      />
    );
  };

  const getUserLinksView = (): ReactNode[] => {
    return linkWeakIds.map((lWid) => {
      return <LinkButton weakId={lWid} key={lWid} />;
    });
  };

  return (
    <Box>
      <Card>
        <Box display={"flex"} justifyContent="space-between">
          <Box
            minW="30%"
            display="flex"
            justifyContent="left"
            alignItems={"center"}
          >
            <Text
              align={"center"}
              color={textColorPrimary}
              fontWeight="bold"
              fontSize="xl"
              ml="10px"
            >
              {linkType.id === OTHER_LINK_TYPE.id
                ? masterLinkTitle
                : linkType.label}
            </Text>
          </Box>
        </Box>

        <Flex w="100%" justifyContent={"space-between"}>
          <Box p={"20px 10px"}>{getIcon(linkType, true)}</Box>
          <Box w={"80%"}>
            <Box pt="10px" w={"100%"}>
              <LinkButton
                weakId={primaryLinkWeakId}
                color={masterLinkColor ? masterLinkColor : linkType.color}
              />
              {getUserLinksView()}
            </Box>
          </Box>
        </Flex>
      </Card>
    </Box>
  );
};

export default MasterLink;
