import { createSlice } from "@reduxjs/toolkit";
import { getAuth } from "firebase/auth";
import { IProfile } from "types/profile/profile";


interface IProfileState extends IProfile {

}

const initialState: IProfileState = {
  firstName: "",
  lastName: "",
  email: "",
  bio: "",
  occupation: "",
  coverPhotoUrl: "",
  profilePhotoUrl: "",
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    saveProfile: (state, action) => {
      const profile: IProfile = action.payload;
      state.firstName = profile.firstName || "";
      state.lastName = profile.lastName || "";
      state.email = profile.email || "";
      state.bio = profile.bio || "";
      state.occupation = profile.occupation || "";
      state.coverPhotoUrl = profile.coverPhotoUrl || "";
      state.profilePhotoUrl = profile.profilePhotoUrl || "";
    }
  },
});

// Action creators are generated for each case reducer function
export const { saveProfile } = profileSlice.actions;

export default profileSlice.reducer;