// Chakra imports
import {
  Box,
  Flex,
  Grid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
// Assets
import { useSelector } from "react-redux";
import { IRootState } from "redux/store";
import MasterLink from "./MasterLink";

interface SectionProps {
  weakId: string;
}

export default function Section(props: SectionProps) {
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const brandColor = useColorModeValue("brand.500", "white");
  const textColorSecondary = "gray.400";
  const box = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const sectionTitle = useSelector(
    (state: IRootState) => state.links.sections.byWeakId[props.weakId]
  ).title;
  const masterLinkWeakIds: string[] = useSelector(
    (state: IRootState) =>
      state.links.sections.byWeakId[props.weakId].masterLinkWeakIds
  );

  return (
    <Box p={'50px'} pt='10px' mb={{ base: "0px", lg: "20px" }}>
      <Flex justifyContent="space-between" mb={'15px'}>
        <Box display={"flex"} alignItems="center">
          <Text           
          color={textColorPrimary}
          fontWeight="bold"
          fontSize="30px"
          mt={"-10px"}
          >{sectionTitle}</Text>
        </Box>
      </Flex>
      <Box>
          <Flex justifyContent={'center'} w='100%'>
            <Grid
              display="inline-grid"
              gridTemplateColumns={{
                base: "1fr",
                lg: "repeat(2, 1fr)",
                "2xl": "repeat(3, 1fr)",
              }}
              gridGap="10"
              w="100%"
            >
              {masterLinkWeakIds.map((mlWid) => (
                <MasterLink key={mlWid} weakId={mlWid}/>
              ))}
            </Grid>
            </Flex>
      </Box>
    </Box>
  );
}
