// Chakra imports
import { Box } from "@chakra-ui/react";
import Footer from "components/footer/FooterAdmin";
// Layout components
import { useState } from "react";
import { Outlet, Route } from "react-router-dom";
import routes from "routes";
import HomeView from "views/home";

// Custom Chakra theme
export default function Home(props: any) {
  const { ...rest } = props;

  return (
    <Box>
      <Box
        minHeight="100vh"
        height="100%"
        overflow="auto"
        position="relative"
        maxHeight="100%"
        w={'100%'}
        transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
        transitionDuration=".2s, .2s, .35s"
        transitionProperty="top, bottom, width"
        transitionTimingFunction="linear, linear, ease"
      >
        <HomeView />
        <Box>
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}
