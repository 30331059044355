// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyCbzJci1twcpyGWSOZJHIiLCZj8MigrSOw",
  authDomain: "styre-d4243.firebaseapp.com",
  projectId: "styre-d4243",
  storageBucket: "styre-d4243.appspot.com",
  messagingSenderId: "299495211070",
  appId: "1:299495211070:web:5373ea7c1ca1553514916f",
  measurementId: "G-0R90SY9MRN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);