import { Action, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { remove } from "lodash";
import { store } from "redux/store";
import { LinkDataResponse } from "types/api";
import {  createEmptyMasterLink, IProfileLinks, IMasterLink, ILink, createEmptyLink } from "types/links/links";
import { LinkType, OTHER_LINK_TYPE } from "types/links/linkType";
import { createEmptySection, ISection } from "types/links/section";
import { IProfile } from "types/profile/profile";
import { NormalizedObjects } from "types/redux/generic";


interface IProfileLinksState {
  sections: NormalizedObjects<ISection>,
  masterLinks: NormalizedObjects<IMasterLink>,
  links: NormalizedObjects<ILink>,
  linkTypes: LinkType[]
}

const initialState: IProfileLinksState = {
  linkTypes: [],
  sections: {
    byWeakId: {},
    allWeakIds: []
  },
  masterLinks: {
    byWeakId: {},
    allWeakIds: []
  },
  links: {
    byWeakId: {},
    allWeakIds: []
  }
};

export const profileLinksSlice = createSlice({
  name: "profileLinks",
  initialState,
  reducers: {
    saveLinkTypes: (state, action) => {
      state.linkTypes = action.payload;
    },
    populateLinksFromServer: (state, action: PayloadAction<LinkDataResponse>) => {
      const sections = [...action.payload.sections];
      sections.sort((a, b) => (a.index || 0) - (b.index || 0));
      state.sections.allWeakIds = sections.map(s => s.weakId);
      action.payload.sections.forEach(section => {
        state.sections.byWeakId[section.weakId] = section;
      });
      state.masterLinks.allWeakIds = action.payload.masterLinks.map(m => m.weakId);
      action.payload.masterLinks.forEach(ml => {
        state.masterLinks.byWeakId[ml.weakId] = ml;
      });
      state.links.allWeakIds = action.payload.links.map(l => l.weakId);
      action.payload.links.forEach(l => {
        state.links.byWeakId[l.weakId] = l;
      });
    }
  },
});

// Action creators are generated for each case reducer function
export const { populateLinksFromServer, saveLinkTypes } = profileLinksSlice.actions;

export default profileLinksSlice.reducer;