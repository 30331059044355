import ReactDOM from "react-dom/client";
import "assets/css/App.css";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "configs/firebaseConfig";
import { Provider } from "react-redux";
import { store } from "redux/store";
import App from "App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
initializeApp(firebaseConfig);

root.render(
  <ChakraProvider theme={theme}>
    <Provider store={store}>
      <App/>
    </Provider>
  </ChakraProvider>
);
