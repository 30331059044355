
// Chakra imports
import { Box } from "@chakra-ui/react";
import ProfileView from "views/profile";

export default function Profile() {
  return (
    <Box>
        <Box
          float='right'
          minHeight='100vh'
          height='100%'
          position='relative'
          w='100%'
          transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
          transitionDuration='.2s, .2s, .35s'
          transitionProperty='top, bottom, width'
          transitionTimingFunction='linear, linear, ease'>
          <ProfileView />
        </Box>
    </Box>
  );
}
